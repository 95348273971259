import { getTransferDetails } from "utils/lib/getTransferDetails";
import { getFormattedAmount, getFormattedDate } from "./number-formatter";
import { getFormattedTime } from "./number-formatter";

export const getTransformedTransaction = (transactions) => {
   // Group transactions by month
   const groupedTransactions = transactions.reduce((acc, transaction) => {
      const monthKey = new Date(transaction.created_at).toLocaleString(
         "default",
         {
            month: "long",
            year: "numeric",
         },
      );

      if (!acc[monthKey]) {
         acc[monthKey] = {
            month: monthKey,
            docs: [],
         };
      }

      acc[monthKey].docs.push({
         ...transaction,
         sender: {
            name: transaction.meta?.paid_from.account_name,
            uniqueId: transaction.meta?.paid_from.account_number,
            bankName: transaction.meta?.paid_from.bankName,
         },

         recipient: {
            name: transaction.meta?.paid_to.account_name,
            uniqueId: transaction.meta?.paid_to.account_number,
            bankName: transaction.meta?.paid_to?.bankName,
         },
         formattedDate: getFormattedDate(transaction.created_at),
         formattedTime: getFormattedTime(transaction.created_at),
         formattedAmount: getFormattedAmount(transaction.amount),
         created: transaction.created_at,
         originalAmount:
            transaction.transaction_type === "funding"
               ? getFormattedAmount(transaction.paid_from?.originalAmount)
               : undefined,
      });

      return acc;
   }, {});

   // Convert to array and sort months in descending order
   return Object.values(groupedTransactions)
      .map((group) => ({
         ...group,
         docs: group.docs.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
         ),
      }))
      .sort((a, b) => new Date(b.month) - new Date(a.month));
};
