import useSWR from "swr";
import { useEffect, useState } from "react";

import { getAllBillsUrl } from "api/services/bills.service";

export default function useBills(config) {
   const [outStandingBills, setOutStandingBills] = useState([]);
   const [sortedBills, setSortedBills] = useState([]);

   const { data: bills, error: getBillsError } = useSWR([
      getAllBillsUrl,
      config,
      "V2",
   ]);

   const { data: paidBills, error: paidBillsError } =
      useSWR("member/bills/paid");

   const getBillsLoading = !bills && !getBillsError;
   const getPaidbillsLoading = !paidBills && !paidBillsError;
   const getAllBillsLoading = getPaidbillsLoading || getBillsLoading;

   useEffect(() => {
      if (bills && paidBills) {
         const formattedPaid = paidBills.map((bill) => ({
            amount: bill.amount,
            unique_id: bill["meta"]["paid_to"]["account_number"],
            title: bill["meta"]["paid_to"]["account_name"],
            _id: bill["_id"],
         }));
         const unpaid = bills.bills.filter(
            (bill) =>
               !formattedPaid.some((b) => b.unique_id === bill.unique_id),
         );
         const paid = bills.bills.filter((bill) =>
            paidBills.some((b) => b.unique_id === bill.unique_id),
         );

         const transformedBill = paidBills.map((bill) => ({
            amount: bill.amount,
            sender: {
               uniqueId: bill["meta"]["paid_from"]["account_number"],
               name: bill["meta"]["paid_from"]["account_name"],
            },
            receiver: {
               uniqueId: bill["meta"]["paid_to"]["account_number"],
               name: bill["meta"]["paid_to"]["account_name"],
            },
            type: "bill",
            created_at: bill["created_at"],
            _id: bill["_id"],
         }));
         setSortedBills(transformedBill);
         setOutStandingBills(unpaid);
      }
   }, [bills, paidBills]);

   return {
      bills: bills?.bills,
      paidBills: sortedBills,
      outStandingBills,
      sortedBills,
      getAllBillsError: getBillsError,
      getAllBillsLoading,
   };
}
