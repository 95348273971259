import useSWR from "swr";
import { useState, useEffect } from "react";
import { getTransactionsUrl } from "api/services/payments.service";
import { useStudent } from "features/auth/auth-context";
import { getTransformedTransaction } from "utils/lib/getTransformedTransactions";
import getSortedData, { formatDataByDay } from "utils/lib/getSortedData";
import useMember from "api/hooks/useStudent";

const getCombinedMonthsData = (data) => {
   return data
      .reduce((result, month) => {
         return [...result, ...month.docs];
      }, [])
      .sort((a, b) => {
         const aDate = new Date(a.created);
         const bDate = new Date(b.created);
         return bDate.getTime() - aDate.getTime();
      });
};

export default function useTransactions(queryParams) {
   const [payouts, setPayouts] = useState([]);
   const [fundings, setFundings] = useState([]);
   const [transformedTransactions, setTransformedTransactions] = useState([]);

   const {
      data: transactions,
      error: transactionsError,
      mutate: mutateTransactions,
      isValidating: transactionsMutating,
   } = useSWR([getTransactionsUrl, queryParams]);

   // const { error: payoutsError } = useSWR(
   //    `${getTransactionsUrl}?transaction_type=payout`,
   //    {
   //       onSuccess: (data) => setPayouts(getCombinedMonthsData(data)),
   //    },
   // );

   // const { error: fundingsError } = useSWR(
   //    `${getTransactionsUrl}?transaction_type=funding`,
   //    {
   //       onSuccess: (data) => setFundings(getCombinedMonthsData(data)),
   //    },
   // );

   const { memberId, mutate: mutateMember } = useMember();

   //refetch transactions, mutate student alongside transactions
   //so the useEffect can run
   const refetchTransactions = () => {
      mutateTransactions();
      mutateMember();
   };

   // const payoutsLoading = !payouts && !payoutsError;
   // const fundingsLoading = !fundings && !fundingsError;
   const transactionsLoading =
      (!transactions && !transactionsError) || transactionsMutating;

   useEffect(() => {
      if (transactions && memberId) {
         console.log(transactions);
         const newTransactions = getTransformedTransaction(
            transactions.transactions,
            memberId,
         );
         setTransformedTransactions(formatDataByDay(newTransactions));
      }
   }, [transactions, memberId]);

   return {
      transactionsError,
      transactionsLoading,
      mutateTransactions,
      refetchTransactions,
      transactions: transformedTransactions,
   };
}
