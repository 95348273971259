import { useState } from "react";

import BillsList from "features/bills/components/bills-list";
import useBills from "features/bills/api/get-bills.api";
import GetBillModal from "features/bills/components/get-single-bill-modal";
import Button from "features/shared/forms/button";
import PageTitle from "features/shared/layout/headers/page-title";
import ArrowBack from "features/shared/layout/nav/arrow-back";

export default function Bills() {
   const { outStandingBills, paidBills, getAllBillsLoading } = useBills();
   const [getBillModal, setGetBillModal] = useState("");
   if (getAllBillsLoading) {
      return <p>Testing</p>;
   }

   return (
      <>
         <section className="wrapper">
            <div className="container pt-20 md:max-w-xl">
               <ArrowBack />
               <PageTitle
                  title="school bills"
                  message="pay your school bills"
               />
               <BillsList
                  bills={outStandingBills}
                  billsLoading={getAllBillsLoading}
                  title="outstanding bills"
                  paid={false}
               />
               <BillsList
                  bills={paidBills}
                  billsLoading={getAllBillsLoading}
                  title="paid bills"
                  paid
               />
               <div className="fixed-btn">
                  <Button onClick={() => setGetBillModal(true)}>
                     Get Bill by ID
                  </Button>
               </div>
            </div>
         </section>
         <GetBillModal
            modalDisplay={getBillModal}
            toggleModal={() => setGetBillModal((prev) => !prev)}
         />
      </>
   );
}
