import { useNavigate } from "react-router-dom";
import AccountIcon from "../../../assets/icons/LedgaV2 Icons/folder-gray.png";
import { useBillContext } from "../bills.context";
import { handleBillDetails } from "../bills.utils";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function AccountsCard({ data, paid }) {
   const navigate = useNavigate();
   const { dispatch } = useBillContext();
   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const handleClick = () => {
      handleBillDetails(data, dispatch);
      navigate("../pay-bill");
   };

   const showReceiptModal = (bill) => {
      showModal(MODAL_TYPES.RECEIPT, {
         id: bill._id,
         amount: bill.amount,
         created: bill.created_at,
         type: bill.transaction_type,
         sender: bill.sender,
         recipient: bill.receiver,
      });
   };
   return (
      <div
         onClick={paid ? () => showReceiptModal(data) : handleClick}
         className="shadow-opacity-80 group relative flex w-full max-w-full flex-row items-center justify-start gap-4 rounded-xl bg-white px-5 text-black-three shadow-[0_4px_10px] shadow-faintShadow-two md:max-w-full md:px-10 md:py-[10px]"
      >
         <img
            src={AccountIcon}
            alt="icon"
            className="h-[40px] text-black-one"
         />
         <div className="h-[90px] w-[2px] bg-black-two"></div>
         <div className="text-start p-5 text-base font-semibold capitalize md:py-6 md:text-lg">
            <p className="poppins-bold text-[20px] text-black-one">
               <span className="poppins-medium text-[16px]">₦</span>
               {getFormattedAmount(data.amount).slice(1)}
            </p>
            <p className="poppins-medium text-[16px] font-normal">
               {data?.receiver?.uniqueId}
            </p>
            <p className="poppins-medium text-[16px] font-normal">
               {data?.receiver?.name}
            </p>
         </div>
      </div>
   );
}
