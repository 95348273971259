import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import usePayBill from "features/bills/api/pay-bill.api";
import NumPadModal from "features/numpad/numpad-modal.component";
import BillTransactionDetails from "features/bills/components/bill-transaction-details";

export default function PayBill() {
   const {
      state,
      numpadModal,
      transferLoading,
      handleSubmit,
      openNumpadModal,
      closeNumpadModal,
      pin,
      setPin,
   } = usePayBill();

   const numpadAmount = state.makePartPayment
      ? state.minimumAmount
      : state.amount;

   if (!state.id) {
      return <Navigate to="../accounts/active" replace />;
   }

   return (
      <Fragment>
         <BillTransactionDetails handleNext={openNumpadModal} />
         {numpadModal && (
            <NumPadModal
               background="BLUE"
               receiver={state.id}
               amount={numpadAmount}
               message="pay bill"
               title="transaction pin"
               btnText="proceed"
               isOpen={numpadModal}
               onClose={closeNumpadModal}
               handleSubmit={handleSubmit}
               btnOnClick={handleSubmit}
               submitLoading={transferLoading}
               pin={pin}
               setPin={setPin}
            />
         )}
      </Fragment>
   );
}
