import AmountModal from "./amount-modal";
import NumPadModal from "features/numpad/numpad-modal.component";
import BankDetailsDisplay from "./bank-details-display";
import useWithdrawal from "features/withdraw/withdraw.api";

export default function WithdrawModals({
   modalDisplay,
   setModalDisplay,
   id = "",
   withdrawService,
   mutateFn,
   balance,
   bankDetails,
}) {
   const {
      amount,
      numpadModal,
      amountModal,
      toggleAmountModal,
      toggleNumpadModal,
      handleNext,
      handleSubmit,
      handleAmountSubmit,
      pin,
      setPin,
   } = useWithdrawal(withdrawService, id, mutateFn, setModalDisplay);

   return (
      <>
         <BankDetailsDisplay
            isOpen={modalDisplay}
            bankDetails={bankDetails}
            handleNext={handleNext}
            onClose={() => setModalDisplay(false)}
         />
         <AmountModal
            balance={balance}
            isOpen={amountModal}
            onClose={toggleAmountModal}
            handleAmountSubmit={handleAmountSubmit}
            bankDetails={bankDetails}
         />
         <NumPadModal
            title="transaction pin"
            bg="BLUE"
            btnText="proceed"
            handleSubmit={handleSubmit}
            btnOnClick={handleSubmit}
            amount={amount}
            message="confirm withdrawal"
            isOpen={numpadModal}
            onClose={toggleNumpadModal}
            pin={pin}
            setPin={setPin}
         />
      </>
   );
}
