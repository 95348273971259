import Axios from "axios";
import { getFromLS } from "./storage";

//initialize instance of Axios with baseURL included
export const axios = Axios.create({
   baseURL: "https://backend-wuov.onrender.com/v1/",
});

//add bearer token to axios request
axios.interceptors.request.use((config) => {
   const token = getFromLS("token");
   if (token) {
      config.headers["Authorization"] = "Bearer " + token;
   }
   return config;
});

export const axiosV2 = Axios.create({
   baseURL: "https://backend-wuov.onrender.com/v2/",
});

//add bearer token to axios request
axiosV2.interceptors.request.use((config) => {
   const token = getFromLS("token");
   if (token) {
      config.headers["Authorization"] = "Bearer " + token;
   }
   return config;
});
