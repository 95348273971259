import { useState } from "react";

import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useWithdrawal(
   withdrawService,
   id = "",
   mutateFn,
   setModalDisplay,
) {
   const [amount, setAmount] = useState("");
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   //modal states
   const [numpadModal, setNumpadModal] = useState(false);
   const [amountModal, setAmountModal] = useState(false);
   const toggleNumpadModal = () => setNumpadModal((prev) => !prev);
   const toggleAmountModal = () => setAmountModal((prev) => !prev);
   const [pin, setPin] = useState("");

   //queries
   const { queryFn: withdrawFn } = useRequest(withdrawService, id);

   //handle functions
   const handleNext = () => {
      setModalDisplay(false);
      setAmountModal(true);
   };

   const handleAmountSubmit = ({ amount }, resetAmountForm) => {
      setAmount(amount);
      setAmountModal(false);
      setNumpadModal(true);
      resetAmountForm();
   };

   const handleSubmit = ({ value: NumpadPin }) => {
      setNumpadModal(false);
      showModal(MODAL_TYPES.LOADING);
      const queryObj = { amount: amount, transaction_pin: pin };
      withdrawFn(queryObj, {
         onSuccess: () => {
            showModal(MODAL_TYPES.SUCCESS, {
               title: "Payout request Successful",
               src: "/icons/arcticons_ok-payment.svg",
               message:
                  "You will receive  your payout in your account once it has been processed",
               btnText: "ok",
               btnOnClick: hideModal,
            });
            mutateFn();
         },

         onError: (message) => {
            if (message.includes("pin you entered is incorrect")) {
               showModal(MODAL_TYPES.ERROR, {
                  title: "incorrect pin",
                  message: `the pin you entered is incorrect \nPlease check your transaction pin and try again`,
               });
            } else if (message.includes("do not have sufficient balance")) {
               showModal(MODAL_TYPES.ERROR, {
                  title: "insufficient funds",
                  message: `you do not have sufficient balance to process this request`,
                  btnText: "back",
               });
            } else {
               showModal(MODAL_TYPES.ERROR, {
                  message: `payout request could not be completed\nplease check your network settings and try again`,
               });
            }
         },
      });
   };

   return {
      amount,
      numpadModal,
      amountModal,
      toggleAmountModal,
      toggleNumpadModal,
      handleNext,
      handleSubmit,
      handleAmountSubmit,
      pin,
      setPin,
   };
}
